
.eg-title {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  margin-bottom: 2rem!important;
  @media (max-width: 576px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px;
  }
}
.eg-item {
  margin: 0 -4.8rem 0;
  padding: 2.4rem 4.8rem;
  .row {
    border-left: 2px solid #BDBDBD;
    margin: 0;
    .eg-item-grid {
      &:first-child {
        border-right: 2px solid #BDBDBD;
      }
    }
  }
  @media (max-width: 576px) {
    padding: 16px;
    margin: 0 -16px;
    .row {
      border: none;
      .eg-item-grid {
        padding: 0;
        border-right: none!important;
      }
    }
  }
  &__title {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem;
    margin-bottom: 2rem!important;
    @media (max-width: 576px) {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }
  &__places {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    @media (max-width: 576px) {
      margin-top: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
