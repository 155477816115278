
.accelerator-menu {
  $icons-main: url('/images/icons-main.png') no-repeat;
  &__item {
    height: 18rem;
    cursor: pointer;
    background: #FBFBFB;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem;
    &__circle {
      height: 6rem;
      line-height: 6rem;
      width: 6rem;
      color: #fff;
      text-align: center;
      border-radius: 5rem;
      background: #1C75BC;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 350;
    }
    &.active {
      background: #1C75BC;
      color: #fff;
      .accelerator-menu__item__circle {
        color: #1C75BC;
        background: #fff;
      }
    }
    &.success {
      .accelerator-menu__item__circle {
        border: #323232 3px solid;
      }
    }
  }
}
